<template>
  <div class="container">
    <h2 class="roboto pt-5 text-center">How can we help you?</h2>
    <form @submit.prevent="searchFaq(search)" class="input-search shadow p-2 mt-5">
      <button type="submit" class="btn"><fawesome-pro icon="search" variant="far" /></button>
      <input type="text" class="form-control" v-model="search" placeholder="Enter a question, keyword, or topic..." />
    </form>
    <h2 class="subtitle roboto pt-5">Most helpful articles</h2>
    <div class="row" v-if="faqs">
      <div class="col-md-4 h-100" v-for="(faq, i) in faqs.faq_favourite.slice(0, 3)" :key="i">
        <p>
          <router-link :to="'/faq/' + faq.faq_slug" v-b-modal.modal-faq>{{faq.faq_name}}</router-link>
        </p>
      </div>
    </div>
    <div class="pt-5" v-for="(faq, i) in filteredFaqs" :key="i">
      <h2 class="subtitle roboto">{{faq.group_name}}</h2>
      <div class="faq-container">
        <div class="faq-collapse" v-for="(d, j) in faq.detail" :key="j">
          <div class="faq-collapse-title collapsed" :class="{'last' : j == faq.detail.length - 1}" @click="toggleBody($event, d.faq_slug)">
            <p>{{d.faq_name}}</p>
          </div>
          <div class="faq-collapse-body"></div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-faq"
      :title="faqModal ? faqModal.name : 'Question'"
      centered
      hide-footer
    >
      <div v-if="!faqModal" class="text-center">
        <font-awesome class="text-center" icon="spinner" size="10x" spin />
      </div>
      <div v-else v-html="faqModal.content"></div>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

export default {
  name: "FAQ",
  data() {
    return {
      search: null,
      faqModal: null,
      faqs: null,
      filteredFaqs: [],
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    },
  },
  mounted() {
    if (this.slug) this.getFaq(this.slug) 
    this.getFaqs()
  },
  methods: {
    async toggleBody(event, slug) {
      const el = event.currentTarget
      const nextEl = el.nextElementSibling
      const isCollapsed = el.classList.toggle("collapsed")
      if (!isCollapsed) {
        nextEl.innerHTML = ""
        try {
          const res = await this.$api.cms.getFaqs(slug)
          if (res.status === 200) nextEl.innerHTML = res.data.data.content
          else nextEl.innerHTML = "<p class='text-center'>Uh-oh... something went wrong when we tried to fetch the answer :(</p>"
        } catch (e) {
          nextEl.innerHTML = "<p class='text-center'>Uh-oh... something went wrong when we tried to fetch the answer :(</p>"
        }
      }
    },
    searchFaq(query) {
      if (query) {
        const faqs = JSON.parse(JSON.stringify(this.faqs.faq_list))
        const filtered = faqs.filter((el) => {
          el.detail = el.detail.filter((rel) => rel.faq_name.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return el.detail.length > 0
        })
        this.filteredFaqs = filtered.length > 0 ? filtered : faqs.filter((el) => el.group_name.toLowerCase().indexOf(query.toLowerCase()) > -1) 
      }
      else this.filteredFaqs = [...this.faqs.faq_list]
    },
    async getFaq(slug) {
      this.faqModal = null
      try {
        const res = await this.$api.cms.getFaqs(slug)
        if (res.status === 200) {
          this.faqModal = res.data.data
          this.$bvModal.show('modal-faq')
        }
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getFaqs() {
      try {
        const res = await this.$api.cms.getFaqs()
        if (res.status === 200) {
          this.faqs = res.data.data
          this.filteredFaqs = [...this.faqs.faq_list]
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    slug(newVal) {
      this.getFaq(newVal)
    }
  }
}
</script>
<style scoped>
.faq-container {
  border: 1px solid #eee;
  border-radius: .5rem;
  padding: 0 1rem;
}
.faq-collapse-title p {
  font-weight: bold;
  color: #d89700;
  max-width: calc(100% - 15px - .5rem);
  margin: 0;
}
.faq-collapse-title {
  cursor: pointer;
  position: relative;
  padding: .5rem 0;
  border-bottom: 1px solid #eee;
}
.faq-collapse-title:after {
  content: "\f077";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  transition: transform .2s ease-out;
}
.faq-collapse-title.collapsed:after {
  transform: rotate(180deg);
}
.faq-collapse-title.collapsed > p {
  font-weight: normal;
  color: black;
}
.faq-collapse-body {
  max-height: 428px;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: visible;
  transition: max-height .4s ease-out;
  border-bottom: 1px solid #eee;
}
.faq-collapse-title.collapsed + .faq-collapse-body {
  max-height: 0;
  visibility: collapse;
}
.faq-collapse-body >>> p {
  font-size: .9rem;
  color: #6e6d71;
  padding: .5rem 0;
  margin: 0;
}
.faq-collapse-body >>> .video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}
.faq-collapse-body >>> .video-container:after {
    padding-top: 56.25%;
    display: block;
    content: '';
}
.faq-collapse-body >>> .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.faq-collapse-title.last {
  border-bottom: none;
}
.faq-collapse-title.last + .faq-collapse-body {
  border-top: 1px solid #eee;
  border-bottom: none;
}
</style>
